import { StringUtils } from "d-react-components";
import CurrencyFormat from "react-currency-format";
import { find, map } from "lodash";
import React, { useContext, useMemo, useRef } from "react";
import Image from "../../components/Image";
import { OrderContext } from "../../context/order";
import { IOrder, IOrderProduct } from "../../interface/order";
import Messages from "../../languages/Messages";
import SectionHeader from "../../components/SectionHeader";
import { mergeProducts } from "../../api/util/product";
import ManualDiscount from "../quotation/ManualDiscount";

interface IOrderProductItem {
    product: IOrderProduct;
    quantity: number;
    salePrice?: number;
}

export const OrderProductItem = ({
    product,
    quantity,
    salePrice,
}: IOrderProductItem) => (
    <div className="d-flex align-items-center pb-3 border-bottom mt-3">
        <Image
            className="image-reg-small"
            src={product?.thumbnail ?? product?.gallery?.[0]}
            style={{ width: "48px", objectFit: "cover" }}
        />
        <div className="flex-column ml-3 w-100">
            <div>{product?.name}</div>
            <small>{`${Messages.sku}: ${product?.sku}`}</small>
            <div className="d-flex justify-content-between w-100">
                <small>{`${Messages.quantity}: ${quantity}`}</small>
                <small>
                    <CurrencyFormat
                        value={salePrice}
                        thousandSeparator
                        displayType="text"
                        prefix="฿"
                        decimalScale={2}
                        fixedDecimalScale
                    />
                </small>
            </div>
        </div>
    </div>
);

const OrderProductsMobile = () => {
    const { orderDetail } = useContext(OrderContext);
    const {
        products: normalProduct,
        manualProduct,
        subtotal,
        total,
        vouchers,
        manualDiscount,
        fee,
    } = orderDetail as IOrder;

    const products = useMemo(() => {
        return mergeProducts(normalProduct, manualProduct);
    }, [orderDetail]);

    const renderSummaryRow = (title: any, content: any) => (
        <div className="d-flex justify-content-between mt-1">
            <label>{title}</label>
            <text>{content}</text>
        </div>
    );

    const renderSummaryPriceRow = (title: any, price: number) =>
        renderSummaryRow(
            title,
            // StringUtils.moneyThaiFormat(price)
            <CurrencyFormat
                displayType="text"
                thousandSeparator
                value={price}
                decimalScale={2}
                fixedDecimalScale
            />
        );

    const renderSummaryVoucherRow = (title: any, price: number) => {
        return renderSummaryRow(
            title,
            // <text className="text-success">
            //     {`-${StringUtils.moneyThaiFormat(price)}`}
            // </text>
            <CurrencyFormat
                displayType="text"
                thousandSeparator
                value={-price}
                decimalScale={2}
                fixedDecimalScale
            />
        );
    };

    const renderOrderSummary = () => (
        <div>
            <SectionHeader label={Messages.summary} hiddenEdit />
            {renderSummaryPriceRow(Messages.subTotal, subtotal)}
            {map(vouchers, (voucher: any) =>
                renderSummaryVoucherRow(
                    `Voucher (${voucher.code})`,
                    voucher?.discount
                )
            )}

            <ManualDiscount manualDiscount={manualDiscount} isMobile />

            {map(fee, (fee: any) => {
                return renderSummaryPriceRow(
                    (Messages as any)[fee?.name as string] || fee?.name,
                    fee?.total
                );
            })}

            {renderSummaryRow(
                <div>{Messages.taxIncluded}</div>,
                // <div>{StringUtils.moneyThaiFormat((total * 7) / 100)}</div>
                <CurrencyFormat
                    displayType="text"
                    thousandSeparator
                    value={(total * 7) / 100}
                    decimalScale={2}
                    fixedDecimalScale
                />
            )}
            {renderSummaryRow(
                Messages.total,
                // <div className="text-bold">
                //     {StringUtils.moneyThaiFormat(total)}
                // </div>
                <CurrencyFormat
                    displayType="text"
                    thousandSeparator
                    value={total}
                    decimalScale={2}
                    fixedDecimalScale
                />
            )}
            <div
                className="p-3 text w-100 bg-note mt-1"
                hidden={!orderDetail.isInvoice}
            >
                This customer requested the tax invoice
            </div>
        </div>
    );

    return (
        <div className="display-mobile-layout">
            <div className="card-container mt-3 p-4 flex-column">
                <SectionHeader label={Messages.items} hiddenEdit />
                {map(products, (product, index) => (
                    <OrderProductItem
                        product={product?.product}
                        quantity={product.quantity}
                        salePrice={product.salePrice}
                        key={index}
                    />
                ))}
            </div>
            <div className="card-container mt-3 p-4 ">
                {renderOrderSummary()}
            </div>
        </div>
    );
};

export default OrderProductsMobile;
