import { Tooltip } from "antd";
import classNames from "classnames";
import {
    Button,
    Checkbox,
    DateInput,
    Icon,
    Notifications,
    Progress,
    TabBar,
    TimeUtils,
    UrlUtils,
    useFirstTime,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, findIndex, map, reduce, split } from "lodash";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import Carousel, { ModalGateway, Modal as ModalImage } from "react-images";
import { Link } from "react-router-dom";
import PaymentAPI from "../../api/payment/PaymentAPI";
import Image from "../../components/Image";
import InputFileArea from "../../components/InputFileArea";
import InputSelectForm from "../../components/InputSelectForm";
import InputTextForm from "../../components/InputTextForm";
import SectionHeader from "../../components/SectionHeader";
import { CONTACT_LINKS, UploadType } from "../../constant/app";
import { ORDER_STATUS } from "../../constant/order";
import {
    PAYMENT_STATUS,
    PaymentMethod,
    SourceOfPayment,
} from "../../constant/payment";
import { OrderContext } from "../../context/order";
import { PaymentDetailContext } from "../../context/quotation";
import { mapOrderPaymentProofToServer } from "../../interface/order";
import { IBankAccount, IPayment } from "../../interface/payment";
import { IPaymentMethod } from "../../interface/paymentMethod";
import Messages from "../../languages/Messages";
import { PaymentBankProofSchema } from "../../schema/order";
import Path from "../Path";

interface IMethodTabView {
    dataSource: any[];
    value: any;
    onChange: any;
    disabled: boolean;
    renderTabContent: () => any;
}

interface IPaymentsProceededContent {
    icon?: any;
    content: any;
}

export const MethodTabView = ({
    dataSource = [],
    value,
    onChange,
    disabled,
    renderTabContent,
}: IMethodTabView) => {
    const renderMethodLabel = (method: IPaymentMethod) => (
        <div className="d-flex justify-content-start w-100 ">
            <Checkbox variant="radio" checked={method.key === value} />
            <div className="ml-3">{(method as any)?.title}</div>
        </div>
    );

    return (
        <div>
            {map(dataSource, (tabItem, index) => {
                const isSelect = value === tabItem?.key;
                const itemClass = classNames(
                    "d-tab-bar__item text-small w-100 border-bottom",
                    {
                        "d-tab-bar__item-active": isSelect,
                    }
                );
                const icon = tabItem?.iconName ?? undefined;
                const disabledButton = disabled || tabItem?.disabled;

                let buttonProps: any = {};
                if (isSelect) {
                    buttonProps = {
                        disabled: disabledButton,
                        style: {
                            borderLeft: "5px solid #587C5F ",
                            borderBottom: "1px solid #0000001a",
                            backgroundColor: "white",
                            color: "#587C5F",
                        },
                    };
                } else {
                    buttonProps = {
                        disabled: disabledButton,
                        style: {
                            backgroundColor: disabledButton
                                ? "#dadada"
                                : "#F5F5F5",
                            borderLeft: "5px solid #F5F5F5 ",
                            color: "rgb(93, 93, 93)",
                        },
                    };
                }
                return (
                    <div key={index}>
                        <Button
                            className={itemClass}
                            onClick={() => onChange && onChange(tabItem)}
                            variant="trans"
                            iconName={icon}
                            {...buttonProps}
                        >
                            {renderMethodLabel(tabItem)}
                        </Button>
                        {isSelect && renderTabContent()}
                    </div>
                );
            })}
        </div>
    );
};

export const BankTransferProof = ({ proof, onClickSlip, payment }: any) => {
    const { nameOfPayer, bankTransferred, dateOfPayment, at } = proof;
    const renderRowItem = (title: any, content: any, hidden = false) => (
        <div hidden={hidden}>{`${title}: ${content}`}</div>
    );
    const proofInfo = (
        <div className="flex-column">
            {renderRowItem(
                Messages.bankOfTransfer,
                (bankTransferred as any)?.[Messages.getLanguage()]?.bankName,
                !bankTransferred
            )}
            {renderRowItem(Messages.nameOfPayer, nameOfPayer)}
            {renderRowItem(
                Messages.dateOfPayment,
                TimeUtils.toDateTime(dateOfPayment)
            )}
        </div>
    );

    const getFileName = (url: string) => {
        const names = split(url, "/");
        return names[names?.length - 1];
    };

    return (
        <div className="flex-column">
            {map(proof?.attachments, (slip, index) => (
                <div
                    key={index}
                    className="d-flex py-3 border-bottom w-100 align-items-center"
                >
                    <div className="d-flex flex-1" style={{ flex: 1 }}>
                        <div
                            className="position-relative"
                            onClick={() => onClickSlip(slip)}
                        >
                            <Image
                                src={slip}
                                className="image-square-small object-cover"
                            />
                            <Tooltip title={proofInfo}>
                                <Icon name="info" className="absolute-right" />
                            </Tooltip>
                        </div>
                        <div className="flex-column ml-3">
                            <text className="font-weight-bold">
                                {getFileName(slip)}
                            </text>
                            <small className="mt-1">
                                {`${Messages.uploadedAt} ${TimeUtils.toDateTime(
                                    at
                                )}`}
                            </small>
                        </div>
                    </div>
                    {[
                        PAYMENT_STATUS.REJECTED,
                        PAYMENT_STATUS.PENDING_CONFIRM,
                    ].includes(payment?.status) && (
                        <div>
                            <Icon
                                name={
                                    payment.status === PAYMENT_STATUS.REJECTED
                                        ? "cancel"
                                        : "check_circle"
                                }
                                size="x-large"
                                color={
                                    payment.status === PAYMENT_STATUS.REJECTED
                                        ? "red"
                                        : "green"
                                }
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export const PaymentStatus = ({ payment }: { payment: IPayment }) => {
    const content = useMemo(() => {
        if (payment.status === PAYMENT_STATUS.PENDING_CONFIRM) {
            return Messages.pendingPaymentConfirmation;
        }
        if (payment.status === PAYMENT_STATUS.REJECTED) {
            return Messages.paymentRejected;
        }
        return "";
    }, [payment.status]);
    if (!content) {
        return null;
    }
    return (
        <div className="d-flex flex-column align-items-center justify-content-center text-center px-4 py-5">
            {content}
            <div className="d-flex mt-5">
                {map(CONTACT_LINKS, (link, index) => (
                    <a href={link.url} key={index}>
                        <Image
                            src={link.icon}
                            className="image-square-medium text-primary mx-4 mx-md-5"
                        />
                    </a>
                ))}
            </div>
        </div>
    );
};

const BankTransferProceed = ({ method }: any) => {
    const { orderDetail, loadPaymentList, loadOrderDetail } =
        useContext(OrderContext);
    const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);
    const { payment } = useContext(PaymentDetailContext);
    const uploadRef = useRef<any>();

    const [imageToView, setImageToView] = useState<any>({
        isVisible: false,
        index: 0,
    });

    useEffect(() => {
        PaymentAPI.bankList(method, orderDetail?.store?.id as string).then(
            (resp) => setBankAccounts(resp as IBankAccount[])
        );
    }, []);

    const proofForm = useFormik<any>({
        initialValues: { method } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: PaymentBankProofSchema,
        onSubmit: (values: any) => {
            const input = mapOrderPaymentProofToServer(values);
            onUploadProofPayment(input);
        },
    });
    const formValues = proofForm?.values;
    const formErrors = proofForm?.errors;

    const onUploadProofPayment = (input: any) => {
        Progress.show(
            {
                method: PaymentAPI.uploadProof as any,
                params: [
                    orderDetail.id,
                    payment?.id,
                    method,
                    SourceOfPayment.ORDER,
                    input,
                ],
            },
            (orderDetailRes: any) => {
                Notifications.showSuccess(Messages.uploadProofPaymentSuccess);
                loadPaymentList();
                loadOrderDetail();
                proofForm.setValues({ method });
                uploadRef.current.refresh();
            }
        );
    };

    const mapProofImage = (url: string) => ({
        source: url,
        caption: "",
    });

    const allPaymentSlip = reduce(
        payment.proof,
        (slips: any, item: any) => {
            const result = map(item.attachments, mapProofImage);
            return [...slips, ...result];
        },
        []
    );

    const classNameInput = "mt-3";

    const onClickSlip = (slip: string) =>
        setImageToView({
            isVisible: true,
            index: findIndex(
                allPaymentSlip,
                (item: any) => item.source === slip
            ),
        });

    const renderBankSelect = (item: IBankAccount) => {
        return (
            <div className="d-flex align-items-center">
                <Image
                    src={item?.bankLogo}
                    className="image-square-x-small object-contain"
                />
                <div className="ml-1">
                    {`${item.bankName} // ${item?.accountNumber}`}
                </div>
            </div>
        );
    };

    return (
        <div>
            <text>{`${Messages.paymentShouldBeMade} ${TimeUtils.toDateTime(
                orderDetail?.expired
            )}. ${Messages.onceYouMadeThePayment}`}</text>
            <div className="border p-4 mt-3">
                <div className="row">
                    {map(bankAccounts, (bank, index) => (
                        <div
                            className="col-12 d-flex mt-3 col-sm-6"
                            key={index}
                        >
                            <Image
                                src={bank?.bankLogo}
                                className="image-square-small object-cover"
                            />
                            <div className="flex-column ml-3">
                                <small>{bank?.bankName}</small>
                                <small>{bank.accountName}</small>
                                <small>{bank?.accountNumber}</small>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <label className="mt-3">{Messages.receiptOfPayment}</label>
            {payment.hasPermissionUploadProofForCustomer &&
                allPaymentSlip?.length < 2 && (
                    <>
                        <InputFileArea
                            onChange={(value: any) =>
                                proofForm.setFieldValue("attachment", value)
                            }
                            className="mt-3"
                            ref={uploadRef}
                            inputParam={{ uploadType: UploadType.ORDER }}
                        />
                        <ViewTextError error={formErrors.attachment} />
                        <InputTextForm
                            form={proofForm}
                            keyData="nameOfPayer"
                            className={classNameInput}
                            label={Messages.fullNameTransferee}
                            required
                        />
                        <InputSelectForm
                            form={proofForm}
                            keyData="bankAccountId"
                            getLabel={renderBankSelect}
                            dataSource={bankAccounts}
                            className={classNameInput}
                            label={Messages.bankTransferTo}
                            required
                        />
                        <DateInput
                            label={Messages.dateOfTransfer}
                            onChange={(val) =>
                                proofForm.setFieldValue(
                                    "dateOfPayment",
                                    moment(val).toISOString()
                                )
                            }
                            value={
                                formValues.dateOfPayment
                                    ? moment(formValues.dateOfPayment)
                                    : (null as any)
                            }
                            className={classNameInput}
                            error={formErrors.dateOfPayment as any}
                            required
                        />
                        <div className="d-flex justify-content-end mt-3">
                            <Button onClick={() => proofForm.handleSubmit()}>
                                {Messages.proceed}
                            </Button>
                        </div>
                    </>
                )}
            <div className="mt-3 flex-column">
                {map(payment?.proof ?? [], (proof, index) => (
                    <BankTransferProof
                        proof={proof}
                        onClickSlip={onClickSlip}
                        payment={payment}
                        key={index}
                    />
                ))}
                {/* @ts-ignore */}
                <ModalGateway>
                    {imageToView.isVisible ? (
                        //@ts-ignore
                        <ModalImage
                            onClose={() => setImageToView({ isVisible: false })}
                        >
                            {/* @ts-ignore */}
                            <Carousel
                                views={allPaymentSlip}
                                currentIndex={imageToView.index}
                            />
                        </ModalImage>
                    ) : null}
                </ModalGateway>
            </div>
        </div>
    );
};

const C2PProceed = ({ method }: any) => {
    const { paymentMethods } = useContext(OrderContext);
    const { payment } = useContext(PaymentDetailContext);
    const [urlPayment, setUrlPayment] = useState<any>();

    const onProceed = () => {
        window.open(urlPayment?.url, "_self");
    };

    useEffect(() => {
        setUrlPayment("");
        const selectedMethod = paymentMethods?.find((m) => m.key === method);
        PaymentAPI.getUrlPayment(payment.id, {
            paymentMethod: selectedMethod?.paymentConfigurationType,
            paymentOption: selectedMethod?.option,
        }).then((resp) => {
            setUrlPayment(resp);
        });
    }, [method]);

    return (
        <div className="p-4">
            <div>{Messages.paySecurelyByUsing2C2P}</div>
            <Image
                src="/images/payment/2c2p.png"
                className="image-reg-x-large w-100"
            />
            {urlPayment && (
                <div className="d-flex justify-content-end">
                    <Button onClick={onProceed} className="mt-3">
                        {Messages.proceed}
                    </Button>
                </div>
            )}
        </div>
    );
};

const PaymentDetail = () => {
    const { paymentMethods } = useContext(OrderContext);
    const { payment } = useContext(PaymentDetailContext);
    const defaultMethodObject = find(paymentMethods, (method) => {
        if (method.paymentConfigurationType === PaymentMethod.PAYMENT_2C2P) {
            return (
                method.option === payment.paymentOption &&
                payment.paymentMethod === PaymentMethod.PAYMENT_2C2P
            );
        }
        return payment?.paymentMethod === method.paymentConfigurationType;
    });
    const methodDefault = defaultMethodObject
        ? defaultMethodObject?.key
        : paymentMethods?.[0]?.key;
    const [methodSelected, setMethodSelected] = useState<string>(
        methodDefault as string
    );
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        setMethodSelected(methodDefault as string);
    }, [payment?.id]);

    const methodTabs = filter(
        map(paymentMethods, (item) => ({
            ...item,
            disabled:
                item.paymentConfigurationType === PaymentMethod.CASH ||
                (item.minimumOrderAmount &&
                    payment.total < item.minimumOrderAmount) ||
                (item.maximumOrderAmount &&
                    payment.total > item.maximumOrderAmount),
        })),
        (item) => item.paymentConfigurationType !== PaymentMethod.DEFAULT
    );

    const renderInfo = (title: string, content: any) => (
        <div className="d-flex mt-1">
            <text className="font-weight-bold">{`${title}: `}</text>
            <text>{content}</text>
        </div>
    );

    const renderPaymentProceed = () => {
        const method = find(
            paymentMethods,
            (method) => method.key === methodSelected
        );
        switch (method?.paymentConfigurationType) {
            case PaymentMethod.BANK_TRANSFER:
                return <BankTransferProceed method={methodSelected} />;
            case PaymentMethod.PAYMENT_2C2P:
                return <C2PProceed method={methodSelected} />;
            default:
                return <div />;
        }
    };
    if (payment?.status === PAYMENT_STATUS.CONFIRMED) {
        return (
            <PaymentsProceededContent
                content={Messages.yourOrderPartiallyPaid}
            />
        );
    }

    return (
        <div className="flex-column mt-3">
            {renderInfo(
                Messages.amount,
                // StringUtils.moneyThaiFormat(payment.total)
                <CurrencyFormat
                    displayType="text"
                    thousandSeparator
                    value={payment.total}
                    decimalScale={2}
                    fixedDecimalScale
                />
            )}
            {renderInfo(Messages.paymentRef, payment?.paymentNo)}
            <div className="font-weight-bold mt-1">
                {Messages.paymentMethod}
                {payment?.paymentMethod !== PaymentMethod.DEFAULT && (
                    <>: {defaultMethodObject?.title}</>
                )}
            </div>
            <div className="row mt-3">
                {payment?.paymentMethod === PaymentMethod.DEFAULT && (
                    <div className="col-12 col-sm-4">
                        <MethodTabView
                            dataSource={methodTabs}
                            value={methodSelected}
                            onChange={(value: any) =>
                                setMethodSelected(value.key)
                            }
                            disabled={
                                payment?.paymentMethod !== PaymentMethod.DEFAULT
                            }
                            renderTabContent={() => (
                                <div className="display-mobile-layout p-3 border-left border-right">
                                    {renderPaymentProceed()}
                                    <PaymentStatus payment={payment} />
                                </div>
                            )}
                        />
                    </div>
                )}
                <div
                    className={classNames(
                        {
                            "col-sm-8 display-desktop-layout":
                                payment?.paymentMethod ===
                                PaymentMethod.DEFAULT,
                        },
                        `col-12`
                    )}
                >
                    {renderPaymentProceed()}
                    <PaymentStatus payment={payment} />
                </div>
            </div>
        </div>
    );
};

const PaymentsProceeding = () => {
    const { paymentId } = UrlUtils.getQuery();

    const { splitPayment, paymentMethods } = useContext(OrderContext);
    const defaultPayment =
        find(splitPayment, (item) => item.id === paymentId) ??
        splitPayment?.[0];

    const [paymentSelected, setPaymentSelected] = useState<IPayment>(
        defaultPayment ?? {}
    );
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        setPaymentSelected(splitPayment?.[0]);
    }, [splitPayment]);

    return (
        <div className="card-container mt-3 p-4">
            <SectionHeader label={Messages.payment} hiddenEdit />
            <TabBar
                className="mt-3"
                dataSource={map(splitPayment, (item, index) => ({
                    ...item,
                    label: `${Messages.payment} ${index + 1}`,
                }))}
                onChange={setPaymentSelected as any}
                value={paymentSelected}
            />
            <PaymentDetailContext.Provider value={{ payment: paymentSelected }}>
                {paymentMethods?.length && <PaymentDetail />}
            </PaymentDetailContext.Provider>
        </div>
    );
};

export const PaymentsProceededContent = ({
    icon = (
        <i
            className="material-icons my-4"
            style={{ color: "#4D7E7F", fontSize: "70px" }}
        >
            check_circle
        </i>
    ),
    content,
}: IPaymentsProceededContent) => (
    <div className="flex-center flex-column mt-3">
        {icon}
        <div className="w-75 text-center">{content}</div>
        <div className="d-flex mt-5">
            {map(CONTACT_LINKS, (link) => (
                <a href={link.url}>
                    <Image
                        src={link.icon}
                        className="image-square-medium text-primary mx-4 mx-md-5"
                    />
                </a>
            ))}
        </div>
    </div>
);

const PaymentsProceeded = () => {
    const { orderDetail } = useContext(OrderContext);
    let icon;
    let description;

    switch (orderDetail.status) {
        case ORDER_STATUS.DELIVERY_PROCESSING:
            description = (
                <div className="flex-column mt-3">
                    <div>{Messages.yourOrderNowProcess}</div>
                    <div>{Messages.contactOurSupport}</div>
                </div>
            );
            icon = (
                <Image
                    src="/images/status/delivery-processing.png"
                    className="image-square-small"
                />
            );
            break;
        case ORDER_STATUS.ORDER_PROCESSING:
            description = (
                <div className="flex-column justify-content-center align-items-center mt-3">
                    <span className="mb-3">{Messages.processingMessage}</span>
                    <Link
                        to={{
                            pathname: Path.APPOINTMENT,
                            search: `?orderRef=${orderDetail.orderNo}&orderId=${orderDetail.id}`,
                        }}
                    >
                        <Button className="text-white" color="primary">
                            {Messages.bookAppointment}
                        </Button>
                    </Link>
                </div>
            );
            break;
        case ORDER_STATUS.CANCELLED:
            description = Messages.cancelledMessage;
            icon = (
                <Image
                    src="/images/status/cancelled.png"
                    className="image-square-xx-small"
                />
            );
            break;
        case ORDER_STATUS.COMPLETED:
            description = Messages.completedMessage;
            break;

        default:
    }

    return (
        <div className="card-container mt-3 p-4">
            <SectionHeader label={Messages.payment} hiddenEdit />
            <PaymentsProceededContent icon={icon} content={description} />
        </div>
    );
};

const OrderPayment = () => {
    const { orderDetail, splitPayment } = useContext(OrderContext);

    const isPendingPayment =
        orderDetail?.status === ORDER_STATUS.PENDING_PAYMENT ||
        orderDetail?.status === ORDER_STATUS.PENDING_CONFIRMATION ||
        orderDetail?.status === ORDER_STATUS.PARTIALLY_PAID;

    if (!isPendingPayment) {
        return <PaymentsProceeded />;
    }
    return <PaymentsProceeding />;
};

export default OrderPayment;
