import classNames from "classnames";
import { TimeUtils } from "d-react-components";
import { find } from "lodash";
import React, { useContext, useMemo } from "react";
import { ORDER_STATUSES } from "../../constant/order";
import { OrderContext } from "../../context/order";
import Messages from "../../languages/Messages";

const GeneralInfo = ({ className, classNameRow }: any) => {
    const { orderDetail } = useContext(OrderContext);
    const { orderNo, createdAt, expired, status } = orderDetail;
    const orderStatus = find(ORDER_STATUSES, (item) => item.id === status);
    const orderStatusLabel = orderStatus?.label ?? "pending";

    const classNameRowInfo = classNames("d-flex mt-1", classNameRow);
    const classNameContainer = classNames("flex-column flex-grow-1", className);

    const renderOrderGeneralInfo = (title: string, content: any) => (
        <div className={classNameRowInfo}>
            <text className="font-weight-bold">{title}</text>
            <text className="ml-1">{content}</text>
        </div>
    );
    return (
        <div className={classNameContainer}>
            {renderOrderGeneralInfo(Messages.orderNo, `#${orderNo}`)}
            {renderOrderGeneralInfo(
                Messages.createdAt,
                TimeUtils.toDateTime(createdAt)
            )}
            {renderOrderGeneralInfo(
                Messages.expireAt,
                TimeUtils.toDateTime(expired)
            )}
            {renderOrderGeneralInfo(
                Messages.status,
                <div style={{ color: orderStatus?.color }}>
                    {(Messages as any)[orderStatusLabel]}
                </div>
            )}
        </div>
    );
};

const OrderGeneralInfo = () => {
    const { branch } = useContext(OrderContext);
    const fullAddress = useMemo(() => {
        return `${branch?.address?.address}, ${branch?.address?.subDistrict?.name}, ${branch?.address?.district?.name}, ${branch?.address?.province?.name}, ${branch?.address?.country?.name}, ${branch?.address?.postcode}`;
    }, [branch]);
    return (
        <>
            <div className="card-container p-4 mt-5 d-flex justify-content-between">
                <div className="flex-column flex-grow-1 justify-content-between">
                    <h4>{branch?.name}</h4>
                    <div>{fullAddress}</div>
                    <div>
                        {Messages.phone}: {branch?.phone}
                    </div>
                    <div>
                        {Messages.taxId}: {branch?.brandTaxId}
                    </div>
                </div>
                <GeneralInfo
                    className="align-items-end display-desktop-layout "
                    classNameRow="justify-content-end"
                />
            </div>
            <div className="display-mobile-layout card-container p-4 mt-3">
                <GeneralInfo classNameRow="justify-content-between" />
            </div>
        </>
    );
};

export default OrderGeneralInfo;
