import {
    AwesomeTableComponent,
    IColumnsProps,
    StringUtils,
} from "d-react-components";
import CurrencyFormat from "react-currency-format";
import { find, map } from "lodash";
import React, { useContext, useMemo, useRef } from "react";
import Image from "../../components/Image";
import { ORDER_FEES } from "../../constant/order";
import { OrderContext } from "../../context/order";
import { IOrder, IOrderProduct } from "../../interface/order";
import Messages from "../../languages/Messages";
import { mergeProducts } from "../../api/util/product";
import ManualDiscount from "../quotation/ManualDiscount";

interface IOrderProductItem {
    product: IOrderProduct;
}

const OrderProductItem = ({ product }: IOrderProductItem) => {
    return (
        <div className="d-flex align-items-center">
            <Image
                className="image-reg-small"
                src={product?.thumbnail || product?.gallery?.[0]}
                style={{ width: "48px", objectFit: "cover" }}
            />
            <div className="flex-column ml-3">
                <div>{product?.name}</div>
                <small>{`${Messages.sku}: ${product?.sku}`}</small>
            </div>
        </div>
    );
};

const OrderProductsDesktop = () => {
    const { orderDetail } = useContext(OrderContext);
    const {
        products: normalProduct,
        subtotal,
        total,
        vouchers,
        manualDiscount,
        fee,
        manualProduct,
    } = orderDetail as IOrder;
    const tableRef = useRef<any>();
    const columns: IColumnsProps = [
        {
            title: Messages.itemName,
            dataIndex: "",
            render: (product) => (
                <OrderProductItem product={product?.product} />
            ),
        },
        {
            title: Messages.unitPrice,
            dataIndex: "salePrice",
            align: "center",
            render: (salePrice, item) => (
                <CurrencyFormat
                    value={
                        !item.isChildren
                            ? salePrice
                            : item.quantity
                            ? salePrice / item.quantity
                            : ""
                    }
                    thousandSeparator
                    displayType="text"
                    prefix="฿"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },
        {
            title: Messages.quantity,
            dataIndex: "quantity",
            align: "center",
        },
        {
            title: Messages.price,
            dataIndex: "salePrice",
            align: "right",
            render: (data, item) => (
                <CurrencyFormat
                    value={
                        item.isChildren
                            ? data
                            : item.quantity
                            ? data * item.quantity
                            : ""
                    }
                    thousandSeparator
                    displayType="text"
                    prefix="฿"
                    decimalScale={2}
                    fixedDecimalScale
                />
            ),
        },
    ];

    const products = useMemo(() => {
        return mergeProducts(normalProduct, manualProduct);
    }, [orderDetail]);

    const renderSummaryRow = (title: any, content: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center border-bottom py-3 pr-2">
                <div>{title}</div>
                <div>{content}</div>
            </div>
        );
    };

    const renderSummaryPriceRow = (title: any, price: number) => {
        return renderSummaryRow(
            title,
            <CurrencyFormat
                displayType="text"
                thousandSeparator
                value={price}
                decimalScale={2}
                fixedDecimalScale
            />
        );
    };

    const renderSummaryVoucherRow = (title: any, price: number) => {
        return renderSummaryRow(
            title,
            <CurrencyFormat
                displayType="text"
                thousandSeparator
                value={-price}
                decimalScale={2}
                fixedDecimalScale
            />
            // <text className="text-success">
            //     {`-${StringUtils.moneyThaiFormat(price)}`}
            // </text>
        );
    };

    const renderOrderSummary = () => (
        <div className=" d-flex justify-content-end">
            <div className="w-50">
                {renderSummaryPriceRow(Messages.subTotal, subtotal)}
                {map(vouchers, (voucher: any) =>
                    renderSummaryVoucherRow(
                        `Voucher (${voucher.code})`,
                        voucher?.discount
                    )
                )}
                <ManualDiscount manualDiscount={manualDiscount} />

                {map(fee, (fee: any) => {
                    return renderSummaryPriceRow(
                        (Messages as any)[fee?.name as string] || fee?.name,
                        fee?.total
                    );
                })}

                {renderSummaryRow(
                    <div>{Messages.taxIncluded}</div>,
                    // <div>{StringUtils.moneyThaiFormat((total * 7) / 100)}</div>
                    <CurrencyFormat
                        displayType="text"
                        thousandSeparator
                        value={(total * 7) / 100}
                        decimalScale={2}
                        fixedDecimalScale
                    />
                )}
                {renderSummaryRow(
                    <div className="text-bold">{Messages.total}</div>,
                    // <div className="text-bold">
                    //     {StringUtils.moneyThaiFormat(total)}
                    // </div>
                    <CurrencyFormat
                        displayType="text"
                        thousandSeparator
                        value={total}
                        decimalScale={2}
                        fixedDecimalScale
                    />
                )}
                <div
                    className="p-3 text w-100 bg-note"
                    hidden={!orderDetail.isInvoice}
                >
                    This customer requested the tax invoice
                </div>
            </div>
        </div>
    );
    return (
        <div className="card-container mt-3 p-4 display-desktop-layout">
            <AwesomeTableComponent
                ref={(ref) => {
                    tableRef.current = ref;
                }}
                columns={columns}
                dataSource={products}
                bordered={false}
                pagination={false}
            />
            {renderOrderSummary()}
        </div>
    );
};

export default OrderProductsDesktop;
